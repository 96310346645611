import { Button, Container, Form, FormSelect, InputGroup, Row, Stack } from 'react-bootstrap'
import { FaSearch, FaTimes } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import DashboardTable from './dashboardTable'
import './dashboard.scss'
import { type IDashboardRow } from '../../interfaces/dashboard'
import { useSearchParams } from 'react-router-dom'
import { getDefaultYear, getYearsList } from '../../utils/reportPeriod'

const Dashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchString, setSearchString] = useState<string>(searchParams.get('ipnCode') ?? '')
  const [reportYear, setReportYear] = useState<number>(getDefaultYear(searchParams))
  const [selectedRows, setSelectedRows] = useState<IDashboardRow[] | null>(null)
  const [search, setSearch] = useState<string>(searchParams.get('search') ?? '')

  useEffect(() => {
    if (searchString !== '') {
      searchParams.set('search', searchString)
      setSearchParams(searchParams)
    } else if (searchParams.has('search')) {
      searchParams.delete('search')
      setSearchParams(searchParams)
    }
  }, [searchString])

  useEffect(() => {
    searchParams.set('reportYear', String(reportYear))
    setSearchParams(searchParams)
  }, [reportYear])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setSearchString(search)
    }, 300)
    return () => { clearTimeout(timeOutId) }
  }, [search])

  const handleSelectionChanged = (data: IDashboardRow[] | null) => {
    setSelectedRows(data)
  }

  return (

    <Container className="pageContainer dashboard">
        <Row>
            <Stack direction="horizontal" gap={3}>
                <div className="p-2">
                    <FormSelect defaultValue={reportYear} onChange={(e: any) => {
                      setReportYear(e.target.value)
                    }}>
                        {getYearsList().map((year) => {
                          return (
                                <option key={year}>{year}</option>
                          )
                        })}
                    </FormSelect>
                </div>
                <div className="p-2">
                    <InputGroup className="search-container">
                        <Form.Control
                            className="search-input"
                            placeholder="Пошук..."
                            value={search}
                            onChange={(e: any) => {
                              setSearch(e.target.value)
                            }}
                        />
                        {search !== ''
                          ? <FaTimes className="icon" onClick={(e: any) => {
                            setSearch('')
                          }}/>
                          : <FaSearch className="icon"/>}
                    </InputGroup>
                </div>
                <div className="p-2 ms-auto">
                    <Button className="add-button">Додати({selectedRows?.length})</Button>
                </div>
            </Stack>
        </Row>
        <Row className="account-table">
            <DashboardTable searchParams={searchParams}
                            handleSelectionChanged={handleSelectionChanged}></DashboardTable>
        </Row>
    </Container>

  )
}

export default Dashboard
