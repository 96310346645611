// eslint-disable-next-line @typescript-eslint/no-unused-vars
export {}

declare global {
  interface String {
    // eslint-disable-next-line @typescript-eslint/method-signature-style
    getTaxDate(this: string): string
  }
  interface Number {
    // eslint-disable-next-line @typescript-eslint/method-signature-style
    getTaxBool(this: number): string
    // eslint-disable-next-line @typescript-eslint/method-signature-style
    getTaxCurrency(this: number): string
  }
}

// eslint-disable-next-line no-extend-native
String.prototype.getTaxDate = function (this: string) {
  return this.substring(0, 2) + '.' + this.substring(2, 4) + '.' + this.substring(4, 8)
}

// eslint-disable-next-line no-extend-native
Number.prototype.getTaxBool = function (this: number) {
  return this === 1 ? 'X' : ''
}

// eslint-disable-next-line no-extend-native
Number.prototype.getTaxCurrency = function (this: number) {
  if (this === undefined || this === null) {
    return ''
  }
  return Intl.NumberFormat('uk-UA', { minimumFractionDigits: 2 }).format(this)
}
