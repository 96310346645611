import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  FormSelect,
  InputGroup,
  Row, Spinner,
  Stack,
  ToggleButton
} from 'react-bootstrap'
import { FaSearch, FaTimes } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import IncomeStatementTable from './incomeStatementTable'
import { type IReportPeriod } from '../../interfaces/reportPeriod'
import api from '../../api'
import { type IIncomeStatement } from '../../interfaces/incomeStatement'
import { useSearchParams } from 'react-router-dom'
import { getDefaultPeriod, getDefaultYear, getYearsList } from '../../utils/reportPeriod'
import GoogleImportIncomeStatementModal from './googleImportIncomeStatementModal'

const IncomeStatement = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchString, setSearchString] = useState<string>(searchParams.get('ipnCode') ?? '')
  const [reportYear, setReportYear] = useState<number>(getDefaultYear(searchParams))
  const [reportPeriodId, setReportPeriodId] = useState<number>(getDefaultPeriod(searchParams))
  const [filter, setFilter] = useState<string>(searchParams.get('filter') ?? 'all')
  const [amountFiler, setAmountFiler] = useState<string>(searchParams.get('amountFiler') ?? 'with')

  const [selectedRows, setSelectedRows] = useState<IIncomeStatement[] | null>(null)
  const [reportPeriodList, setReportPeriodList] = useState<IReportPeriod[] | null>(null)
  const [search, setSearch] = useState<string>(searchParams.get('search') ?? '')
  const [total, setTotal] = useState<number>(0)
  const [showGoogleImportIncomeStatementModal, setShowGoogleImportIncomeStatementModal] = useState<boolean>(false)
  const [actionSpinner, setActionSpinner] = useState<string>('')
  const filters = [
    { name: 'Всі', value: 'all' },
    { name: 'Без декларації', value: 'withoutDocument' },
    { name: 'Декларація (не відправлена)', value: 'withValidDocument' },
    { name: 'Відправлено в ДПС', value: 'withSentDocument' },
    { name: 'Прийнято', value: 'withAcceptedDocument' },
    { name: 'Вручну', value: 'withManuallyDocument' },
    { name: 'Відхилено', value: 'withRejectedDocument' }
  ]

  useEffect(() => {
    api.get('reportPeriod', {
      params: {
        filter: 'income'
      }
    })
      .then(res => {
        const newData: IReportPeriod[] = (res.data as IReportPeriod[])
        setReportPeriodList(newData)
        setReportPeriodId(getDefaultPeriod(searchParams))
      }
      )
      .catch(() => {})
  }, [])

  useEffect(() => {
    if (searchString !== '') {
      searchParams.set('search', searchString)
      setSearchParams(searchParams)
    } else if (searchParams.has('search')) {
      searchParams.delete('search')
      setSearchParams(searchParams)
    }
  }, [searchString])

  useEffect(() => {
    searchParams.set('reportYear', String(reportYear))
    setSearchParams(searchParams)
  }, [reportYear])

  useEffect(() => {
    if (reportPeriodId !== 0) {
      searchParams.set('reportPeriodId', String(reportPeriodId))
      setSearchParams(searchParams)
    }
  }, [reportPeriodId])

  useEffect(() => {
    if (filter !== '') {
      searchParams.set('filter', filter)
      setSearchParams(searchParams)
    } else if (searchParams.has('filter')) {
      searchParams.delete('filter')
      setSearchParams(searchParams)
    }
  }, [filter])

  useEffect(() => {
    if (amountFiler !== '') {
      searchParams.set('amountFiler', amountFiler)
      setSearchParams(searchParams)
    } else if (searchParams.has('amountFiler')) {
      searchParams.delete('amountFiler')
      setSearchParams(searchParams)
    }
  }, [amountFiler])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setSearchString(search)
    }, 300)
    return () => { clearTimeout(timeOutId) }
  }, [search])
  const handleSelectionChanged = (data: IIncomeStatement[] | null) => {
    setSelectedRows(data)
  }

  const handleTotal = (value: number) => {
    setTotal(value)
  }

  const handleCloseGoogleImportIncomeStatementModal = (needReloadData: boolean) => {
    if (needReloadData) {
      searchParams.set('reloadTime', String((new Date()).getTime()))
      setSearchParams(searchParams)
    }
    setShowGoogleImportIncomeStatementModal(false)
  }

  const handleAction = (operation: string, selectedRows: IIncomeStatement[]) => {
    setActionSpinner(operation)
    let data = {}
    if (selectedRows.length !== 0) {
      data = { ids: selectedRows.map((item) => { return item.id }) }
    }

    searchParams.forEach((value, key) => {
      switch (key) {
        case 'reloadTime':
          break
        case 'reportPeriodId':
        case 'reportYear':
          Object.assign(data, { [key]: Number(value) })
          break
        default:
          Object.assign(data, { [key]: value })
      }
    })

    api.post(`/incomeStatement/${operation}`, data)
      .then(res => {
        searchParams.set('reloadTime', String((new Date()).getTime()))
        setSearchParams(searchParams)
      })
      .catch(() => {})
      .finally(() => { setActionSpinner('') })
  }

  return (

        <Container className="pageContainer">
            <Row>
                <Stack direction="horizontal" gap={4}>
                    <div className="mx-4 pt-3 lh-1">
                        <p className="fs-3 fw-bold">{total}</p>
                    </div>
                    <div className="p-2">
                        <FormSelect defaultValue={reportYear} onChange={(e: any) => {
                          setReportYear(e.target.value)
                        }}>
                            {getYearsList().map((year) => {
                              return (
                                    <option key={year}>{year}</option>
                              )
                            })}
                        </FormSelect>
                    </div>
                    <div className="p-2">
                        {reportPeriodId !== null &&
                            <FormSelect value={reportPeriodId} onChange={(e: any) => {
                              setReportPeriodId(Number(e.target.value))
                            }}>
                                {reportPeriodList?.map(item => {
                                  return (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                  )
                                })}
                            </FormSelect>}
                    </div>
                    <div className="p-2">
                        <InputGroup className="search-container">
                            <Form.Control
                                className="search-input"
                                placeholder="Пошук..."
                                value={search}
                                onChange={(e: any) => {
                                  setSearch(e.target.value)
                                }}
                            />
                            {search !== ''
                              ? <FaTimes className="icon" onClick={(e: any) => {
                                setSearch('')
                              }}/>
                              : <FaSearch className="icon"/>}
                        </InputGroup>
                    </div>
                    {
                        {
                          all: <div className="p-2 ms-auto">
                                    <Button className="add-button mx-3" onClick={ (e) => { setShowGoogleImportIncomeStatementModal(true) }}>Google sheet</Button>
                                    <GoogleImportIncomeStatementModal show={showGoogleImportIncomeStatementModal} handleClose={handleCloseGoogleImportIncomeStatementModal} reportYear={reportYear}/>
                                </div>,
                          withoutDocument: <div className="p-2 ms-auto">
                                                <Button className="add-button" onClick={(e: any) => { handleAction('createAll', []) }}>
                                                    {actionSpinner === 'createAll' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} Додати всім</Button>
                                                <Button className="add-button mx-3" onClick={(e: any) => { handleAction('createSelected', selectedRows!) }} disabled={selectedRows?.length === 0}>
                                                    {actionSpinner === 'createSelected' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} Додати вибраним({selectedRows?.length})
                                                </Button>
                                            </div>,
                          withValidDocument: <div className="p-2 ms-auto">
                                                <Button className="add-button" onClick={(e: any) => { handleAction('sendAll', []) }}>
                                                    {actionSpinner === 'sendAll' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}  Надіслати всі </Button>
                                                <Button className="add-button mx-3" onClick={(e: any) => { handleAction('sendSelected', selectedRows!) }} disabled={selectedRows?.length === 0}>
                                                    {actionSpinner === 'sendSelected' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}  Надіслати вибрані({selectedRows?.length})
                                                </Button>
                                                <Button className="add-button" onClick={(e: any) => { handleAction('sentManually', []) }} disabled={selectedRows?.length === 0}>
                                                    {actionSpinner === 'sentManually' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} Подано вручну({selectedRows?.length}) </Button>
                                            </div>,
                          withRejectedDocument: <div className="p-2 ms-auto">
                                <Button className="add-button mx-3" onClick={(e: any) => { handleAction('createSelected', selectedRows!) }} disabled={selectedRows?.length === 0}>
                                    {actionSpinner === 'createSelected' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}  Додати вибраним({selectedRows?.length})
                                </Button>
                                <Button className="add-button" onClick={(e: any) => { handleAction('sentManually', []) }} disabled={selectedRows?.length === 0}>
                                  {actionSpinner === 'sentManually' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} Подано вручну({selectedRows?.length}) </Button>
                            </div>
                        }[filter]
                    }

                </Stack>
            </Row>
            <Row>
                <Col className="px-4 py-2">
                    <ButtonGroup size="sm">
                        {filters.map((item, idx) => (
                            <ToggleButton
                                key={idx}
                                style={{ minWidth: '9em' }}
                                id={`filter-${idx}`}
                                type="radio"
                                variant={'outline-primary'}
                                name="filter"
                                value={item.value}
                                checked={filter === item.value}
                                onChange={(e) => { setFilter(e.currentTarget.value) }}
                            >{item.name}</ToggleButton>
                        ))}
                    </ButtonGroup>

                    <ButtonGroup size="sm" className="ps-4">
                        <ToggleButton
                            id="amountFilerWith"
                            type="checkbox"
                            variant={'outline-success'}
                            checked={amountFiler === 'with'}
                            value="with"
                            onChange={(e) => { setAmountFiler(e.currentTarget.value) }}
                        >
                            З доходом
                        </ToggleButton>
                        <ToggleButton
                            id="amountFilerWithout"
                            type="checkbox"
                            variant={'outline-warning'}
                            checked={amountFiler === 'without'}
                            value="without"
                            onChange={(e) => { setAmountFiler(e.currentTarget.value) }}
                        >
                            Без доходу
                        </ToggleButton>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className="account-table">
                <IncomeStatementTable searchParams={searchParams} handleSelectionChanged={handleSelectionChanged} handleTotal={handleTotal}/>
            </Row>
        </Container>

  )
}

export default IncomeStatement
