import { Col, Container, Form, Row } from 'react-bootstrap'
import { FaEye, FaFileAlt } from 'react-icons/fa'
import './dashboardTableRow.scss'
import { useEffect, useState } from 'react'
import type { IDashboardRow, IDashboardRowData } from '../../interfaces/dashboard'
import api from '../../api'
import type { IDocument } from '../../interfaces/document'
import DocumentViewModal from '../document/documentView'

export interface DashboardTableRowProps {
  row: IDashboardRow
  checked: boolean
  handleRowSelectionChanged: (row: IDashboardRow | null) => void
}

const DashboardTableRow = ({ row, checked, handleRowSelectionChanged }: DashboardTableRowProps) => {
  const [selected, setSelected] = useState<boolean>(false)

  useEffect(() => {
    setSelected(checked)
  }, [checked])

  useEffect(() => {
    row.isChecked = selected
    handleRowSelectionChanged(row)
  }, [selected])

  return (
      <tr key={row.id}>
          <td className="align-middle"><Form.Check.Input checked={selected} onChange={() => { setSelected(!selected) }}/></td>
          <td>{row.ipnCode}</td>
          <td>{row.name}</td>
          <td><DashboardTableRowPeriodData data={row.data.find((item) => {
            return item.reportPeriodId === 4
          })!}></DashboardTableRowPeriodData></td>
          <td><DashboardTableRowPeriodData data={row.data.find((item) => {
            return item.reportPeriodId === 9
          })!}></DashboardTableRowPeriodData></td>
          <td><DashboardTableRowPeriodData data={row.data.find((item) => {
            return item.reportPeriodId === 14
          })!}></DashboardTableRowPeriodData></td>
          <td><DashboardTableRowPeriodData data={row.data.find((item) => {
            return item.reportPeriodId === 20
          })!}></DashboardTableRowPeriodData></td>
      </tr>
  )
}

export interface DashboardTableRowDataProps {
  data: IDashboardRowData
}
const DashboardTableRowPeriodData = ({ data }: DashboardTableRowDataProps) => {
  const [showDocumentViewModal, setShowDocumentViewModal] = useState<boolean>(false)
  const [document, setDocument] = useState<IDocument | null>(null)

  const handleViewDocument = (documentId: number) => {
    api.get(`/document/${documentId}`)
      .then(res => {
        setDocument(res.data as IDocument)
        setShowDocumentViewModal(true)
      })
      .catch(e => {
        // TODO: buttify error
        console.log(e)
      })
  }

  const handleViewReceipt = (receiptId: number) => {
    api.get(`/receipt/${receiptId}`)
      .then(res => {
        const doc = res.data as IDocument
        doc.type = 'J1499102'
        setDocument(doc)
        setShowDocumentViewModal(true)
      })
      .catch(e => {
        // TODO: buttify error
        console.log(e)
      })
  }

  const handleCloseDocumentViewModal = () => {
    setShowDocumentViewModal(false)
  }

  return (
        <Container className="dashboard-ceil">
            <Row>
            <Col>Сума доходу</Col>
            <Col className="text-end">{data?.incomeAmount?.toLocaleString('uk-UA', { minimumFractionDigits: 2 })}</Col>
        </Row>
        <Row>
          <Col >Податок</Col>
          <Col className="text-end">{data?.taxAmount.toLocaleString('uk-UA', { minimumFractionDigits: 2 })}</Col>
        </Row>
        <Row>
          <Col className="text-end">
              {data?.documents?.[0].id !== undefined &&
                  <FaEye className="mx-1" style={{ color: 'grey' }} onClick={() => { handleViewDocument(data.documents?.[0].id ?? 0) }} size={18}/>
              }
              {data?.documents?.[0]?.receipts?.[0]?.id !== undefined &&
                  <FaFileAlt size={16} onClick={() => { handleViewReceipt(data.documents?.[0].receipts[0].id ?? 0) }} className={((data.documents?.[0]?.receipts?.[0]?.status >= 0) ? 'receipt-success' : 'receipt-error')}/>
              }

              {data?.documents?.[0]?.receipts?.[1]?.id !== undefined &&
                  <FaFileAlt size={16} onClick={() => { handleViewReceipt(data.documents?.[0].receipts[1]?.id ?? 0) }} className={((data.documents?.[0]?.receipts?.[1]?.status >= 0) ? 'receipt-success' : 'receipt-error')}/>
              }

          </Col>
        </Row>
            <DocumentViewModal show={showDocumentViewModal} doc={document!} handleClose={handleCloseDocumentViewModal}/>
      </Container>
  )
}

export default DashboardTableRow
