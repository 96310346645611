import { Col, Row, Spinner, Stack, Table } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import api from '../../api'
import type IAccount from '../../interfaces/account'
import { useNavigate } from 'react-router-dom'
import './accountTable.scss'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FaBan, FaRegFileExcel } from 'react-icons/fa'
import { convertISODateToDatetimeString } from '../../utils/convert'

interface AccountTableProps {
  searchParams: URLSearchParams
  handleTotal: (total: number) => void
}
const AccountTable = ({ searchParams, handleTotal }: AccountTableProps) => {
  const [data, setData] = useState<IAccount[] | null>(null)
  const [reloadData, setReloadData] = useState<Date>(new Date())
  const navigate = useNavigate()
  const [offset, setOffset] = useState<number | null>(null)
  const [hasMore, setHasMore] = useState<boolean>(true)

  useEffect(() => {
    getData()
  }, [reloadData])

  useEffect(() => {
    setOffset(0)
    setHasMore(true)
    setData(null)
    setReloadData(new Date())
  }, [searchParams])

  const getData = () => {
    if (offset === null) {
      return
    }
    const queryParams = searchParams.toString()
    api.get(`accounts?${queryParams}`, {
      params: { offset }
    })
      .then(res => {
        let newData: IAccount[]
        newData = (res.data as IAccount[])
        if (newData.length < 30) {
          setHasMore(false)
        }

        if (data !== null) {
          newData = data?.concat(res.data as IAccount[])
        }
        setOffset(newData.length)
        setData(newData)
      }
      )
      .catch(e => { console.log(e) }
      )

    api.get(`accounts/total?${queryParams}`)
      .then(res => {
        handleTotal(res.data.count)
      }
      )
      .catch(e => { console.log(e) }
      )
  }
  const dataLen = (data: any): number => {
    if (data != null) {
      return data.length
    }
    return 0
  }
  const getDownloadUrl = (): string => {
    const queryParams = searchParams.toString()
    return process.env.REACT_APP_API_URL + `/accounts/download?${queryParams}`
  }

  const handleAccount = (id: number) => { navigate(`${id}`) }

  return (
      <div className="accountTable">
      <InfiniteScroll dataLength={dataLen(data)} next={getData} hasMore={hasMore} loader={
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner animation="border" variant="success"/>
              </div>} style={{ overflow: 'unset' }}>
          <Table striped>
              <thead>
              <tr>
                  <th>#</th>
                  <th>ІПН</th>
                  <th>ПІБ</th>
                  <th>Телефон</th>
                  <th>Адреса</th>
                  <th>
                      <Stack direction="horizontal">
                          {searchParams.get('isDeregistred') === 'true'
                            ? <span>Дата зняття з реєстрації</span>
                            : <span>ЕЦП</span>}
                          <div className="ms-auto">
                              <a href={getDownloadUrl()}>
                                <FaRegFileExcel size={20} className="text-success d-flex flex-row-reverse"/>
                              </a>
                          </div>
                      </Stack>

                  </th>
              </tr>
              </thead>
              <tbody>
              {data?.map((item, idx) => {
                return (
                      <tr key={item.id} onClick={(e: any) => { handleAccount(item.id ?? 0) }}>
                        <td>{idx + 1}</td>
                        <td>
                            <Row>
                                <Col xs={1}>{item.stiDeregDate !== undefined && <FaBan size={12} style={{ color: 'red' }}/>}</Col>
                                <Col>{item.ipnCode}</Col>
                            </Row>
                        </td>
                        <td>{item.name}</td>
                        <td>{item.phone}</td>
                        <td>{item.address}</td>
                        <td>
                            {
                                {
                                  false: <div onClick={(e: any) => {
                                    handleAccount(item.id ?? 0)
                                  }}>{convertISODateToDatetimeString(item.keyFiles?.[0]?.certEndTime)}</div>,
                                  true: <div>{item.stiDeregDate}</div>
                                }[searchParams.get('isDeregistred') ?? 'false']
                            }
                            </td>
                    </tr>
                )
              })}
            </tbody>
        </Table>
      </InfiniteScroll>
      </div>
  )
}

export default AccountTable
