import { createContext, type ReactNode, useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import api from '../api'
import { type IUser } from '../interfaces/user'

interface IAuthContext {
  user: IUser | null
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
}

const AuthContext = createContext<IAuthContext>(undefined!)

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IUser | null>(null)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    api.get('auth/me')
      .then(res => {
        const user = res.data
        setUser(user as IUser)
      })
      .catch(() => {
        setUser(null)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])
  const login = async (email: string, password: string) => {
    try {
      const response = await api.post('auth/login', { login: email, password })
      setUser(response.data as IUser)
      navigate(searchParams.get('goto') ?? '/account')
    } catch (e) {
      console.log(e)
    }
  }

  const logout = async () => {
    try {
      await api.post('auth/logout')
      setUser(null)
      navigate('/login')
    } catch (e) {
      console.log(e)
    }
  }

  return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {loading ? null : children}
        </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
export default AuthProvider
