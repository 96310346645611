import React from 'react'
import AuthProvider from './hooks/authProvider'
import { useRoutes } from 'react-router-dom'
import { router } from './router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

function App () {
  const routeResult = useRoutes(router)
  return (
        <AuthProvider>
          {routeResult}
          <ToastContainer limit={3}/>
        </AuthProvider>
  )
}

export default App
