// @ts-nocheck

import './taxTypes'
import type TaxDocumentProps from '../../interfaces/taxDocumentProps'

interface F1499102Data extends ITaxDocBody {
    HTIN:       string
    HNAME:      string
    HEMAIL:     string
    HDOCKOD:    string
    HDOCNAME:   string
    HFILENAME:  string
    HDOCSTAN:   string
    HPERIOD:    string
    HZY:        string
    HDTERM?:     string
    HTIN1:      string
    HNAME1:     string
    HKBOS:      string
    HBOS:       string
    HKBUH:      string
    HBUH:       string
    HRESULT:    string
    HDATE:      string
    HTIME:      string
    HSENDER:    string
    TEXT:       string
    T1RXXXXG1S?: string[]
    HNUMREG:    string
}

// https://magic.reactjs.net/htmltojsx.htm

const F1499102 = ({ doc }: TaxDocumentProps) => {
  const body = doc.taxDoc.Body as F1499102Data

  return (
      <div className="jdm-rpt-content doc-content">
          <table form="true" cellSpacing={0} cellPadding={0} border={0} className="ui-jdm" width="720" style={{marginLeft: 'auto', marginRight: 'auto', borderColor: 'grey'}}>
              <tbody>
              <tr>
                  <td>
                      <table width="100%" border={1} cellSpacing={0} cellPadding={0} style={{borderTopWidth: '1px'}}  className="tmplPageDoc content tmplKvt">
                          <tbody>
                          <tr>
                              <td align="center" style={{ fontSize: '1.25em', fontWeight: 'bold' }}>Квитанція № {doc.num}</td>
                          </tr>
                          </tbody>
                      </table>
                  </td>
              </tr>
              <tr>
                  <td>
                      <table width="100%" border={1} cellSpacing={0} cellPadding={0} className="tmplPageDoc content tmplKvt">
                          <tbody>
                          <tr>
                              <td width="20%" rowSpan={6} style={{ fontSize: '1em', verticalAlign: 'top' }}>Платник податків:</td>
                              <td width="85%" style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div className="fc " p="HTIN" tabno={0}
                                       style={{textAlign: 'left', width: '160px!important'}}>
                                      {body.HTIN}
                                  </div>
                              </td>
                          </tr>
                          <tr>
                              <td align="center" style={{ fontSize: '0.55em' }}><font size={-1}>(код згідно з ЄДРПОУ або РНОКПП або серія та номер паспорта)</font></td>
                          </tr>
                          <tr>
                              <td style={{ fontSize: '1em', fontWeight: 'bold' }}  className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div style={{width: '100%', textAlign: 'left'}} className="fc " p="HNAME" tabno={0}>
                                      {body.HNAME}
                                  </div>
                              </td>
                          </tr>
                          <tr>
                              <td align="center" style={{ fontSize: '0.55em' }}><font size={-1}>(найменування або прізвище, ім'я, по батькові)</font></td>
                          </tr>
                          <tr>
                              <td style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div style={{width: '100%', textAlign: 'left'}} className="fc " p="HEMAIL" tabno={0}>
                                      {body.HEMAIL}
                                  </div>
                              </td>
                          </tr>
                          <tr>
                              <td align="center" style={{ fontSize: '0.55em' }}><font size={-1}>(адреса електронної пошти (E-mail))</font></td>
                          </tr>
                          </tbody>
                      </table>
                  </td>
              </tr>
              <tr>
                  <td>
                      <table width="100%" border={1} cellSpacing={0} cellPadding={0} className="tmplPageDoc content tmplKvt">
                          <tbody>
                          <tr>
                              <td width="20%" rowSpan={12} style={{ fontSize: '1em', verticalAlign: 'top' }}> Документ:</td>
                              <td colSpan={2} style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div className="fc " p="HDOCKOD" tabno={0} style={{width: '99%!important'}}>{body.HDOCKOD}</div>
                              </td>
                          </tr>
                          <tr>
                              <td colSpan={2} align="center" style={{ fontSize: '0.55em' }}><font size={-1}>(код форми документу)</font></td>
                          </tr>
                          <tr>
                              <td colSpan={2} style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div style={{width: '100%'}} className="fc " p="HDOCNAME" tabno={0}>{body.HDOCNAME}</div>
                              </td>
                          </tr>
                          <tr>
                              <td colSpan={2} align="center" style={{ fontSize: '0.55em' }}><font size={-1}>(назва документу звітності)</font></td>
                          </tr>
                          <tr>
                              <td colSpan={2} style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div style={{width: '100%', textAlign: 'left'}} className="fc " p="HFILENAME"
                                       tabno={0}>{body.HFILENAME}</div>
                              </td>
                          </tr>
                          <tr>
                              <td colSpan={2} align="center" style={{ fontSize: '0.55em' }}><font size={-1}>(ім’я файлу)</font></td>
                          </tr>
                          <tr>
                              <td colSpan={2} style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div style={{width: '100%'}} className="fc " p="HDOCSTAN" tabno={0}>{body.HDOCSTAN}</div>
                              </td>
                          </tr>
                          <tr>
                              <td colSpan={2} align="center" style={{ fontSize: '0.55em' }}><font size={-1}>(стан документа)</font></td>
                          </tr>
                          <tr>
                              <td width="40%" style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div style={{width: '100%', textAlign: 'right'}} className="fc " p="HPERIOD" tabno={0}>{body.HPERIOD}</div>
                              </td>
                              <td width="45%" style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div className="fc " p="HZY" tabno={0}
                                       style={{textAlign: 'left', width: '96px!important'}}> {body.HZY}</div>
                              </td>
                          </tr>
                          <tr>
                              <td align="center" style={{ fontSize: '0.55em' }}><font size={-1}>(назва звітного періоду)</font>
                              </td>
                              <td align="center" style={{ fontSize: '0.55em' }}><font size={-1}>(звітний рік)</font></td>
                          </tr>
                          <tr>
                              <td colSpan={2} style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div className="fc " p="HDTERM" tabno={0}
                                       style={{textAlign: 'center', width: '160px!important'}}>{body.HDTERM?.getTaxDate()}</div>
                              </td>
                          </tr>
                          <tr>
                              <td colSpan={2} align="center" style={{ fontSize: '0.55em' }}><font size={-1}>(граничний термін подання (для уточнюючих не зазначається))</font>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                  </td>
              </tr>
              <tr>
                  <td>
                      <table width="100%" border={1} cellSpacing={0} cellPadding={0} className="tmplPageDoc content tmplKvt">
                          <tbody>
                          <tr>
                              <td width="20%" rowSpan={12} style={{ fontSize: '1em', verticalAlign: 'top' }}> Підписи документа:</td>
                              <td colSpan={3} align="center" style={{ fontSize: '0.55em' }}><font size={-1}>(зазначається лише в залежності від статусу особи платника
                                  податків та складу посадових осіб, що мають право підпису):</font></td>
                          </tr>
                          <tr>
                              <td width="20%" rowSpan={2}> печатка:</td>
                              <td width="15%" style={{ fontSize: '1em', fontWeight: 'bold' }}>
                                  <div className="fc " p="HTIN1" tabno={0}
                                       style={{textAlign: 'left', width: '160px!important'}} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">{body.HTIN1}</div>
                              </td>
                              <td width="70%" style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div style={{width: '100%', textAlign: 'left'}} className="fc " p="HNAME1" tabno={0}>{body.HNAME1}</div>
                              </td>
                          </tr>
                          <tr>
                              <td align="center"><font size={-1} style={{ fontSize: '0.55em' }}>(код згідно з ЄДРПОУ або РНОКПП або серія та номер паспорта)</font></td>
                              <td align="center"><font size={-1} style={{ fontSize: '0.55em' }}> (найменування або прізвище, ім'я, по батькові)</font></td>
                          </tr>
                          <tr>
                              <td width="30%" rowSpan={2}> Керівник або фізична особа - платник податку, або співробітник з правом
                                  підпису ПН
                              </td>
                              <td style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div className="fc " p="HKBOS" tabno={0}
                                       style={{textAlign: 'left', width: '160px!important'}}>{body.HKBOS}</div>
                              </td>
                              <td style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div style={{width: '100%', textAlign: 'left'}} className="fc " p="HBOS" tabno={0}>{body.HBOS}</div>
                              </td>
                          </tr>
                          <tr>
                              <td align="center"><font size={-1} style={{ fontSize: '0.55em' }}> (РНОКПП або серія та номер паспорта) </font></td>
                              <td align="center"><font size={-1} style={{ fontSize: '0.55em' }}> (прізвище, ім'я, по батькові)</font></td>
                          </tr>
                          <tr>
                              <td rowSpan={2}> бухгалтер:</td>
                              <td style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div className="fc " p="HKBUH" tabno={0}
                                       style={{textAlign: 'left', width: '160px!important'}}>{body.HKBUH}</div>
                              </td>
                              <td style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div style={{width: '100%', textAlign: 'left'}} className="fc " p="HBUH" tabno={0}>{body.HBUH}</div>
                              </td>
                          </tr>
                          <tr>
                              <td align="center"><font size={-1} style={{ fontSize: '0.55em' }}> (РНОКПП або серія та номер паспорта) </font></td>
                              <td align="center"><font size={-1} style={{ fontSize: '0.55em' }}> (прізвище, ім'я, по батькові)</font></td>
                          </tr>
                          </tbody>
                      </table>
                  </td>
              </tr>
              <tr>
                  <td>
                      <table width="100%" border={1} cellSpacing={0} cellPadding={0} className="tmplPageDoc content tmplKvt">
                          <tbody>
                          <tr>
                              <td width="20%" rowSpan={5} style={{ fontSize: '1em', verticalAlign: 'top' }}> Результат обробки:</td>
                              <td colSpan={2} align="center"> Документ доставлено до Державної фіскальної служби України
                              </td>
                          </tr>
                          <tr>
                              <td colSpan={2} style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div style={{width: '100%'}} className="fc " p="HRESULT" tabno={0}>{body.HRESULT}</div>
                              </td>
                          </tr>
                          <tr>
                              <td width="40%" align="right" style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div className="fc " p="HDATE" tabno={0}
                                       style={{textAlign: 'center', width: '160px!important'}}>{body.HDATE.getTaxDate()}</div>
                              </td>
                              <td width="45%" style={{ fontSize: '1em', fontWeight: 'bold' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                  <div className="fc " p="HTIME" tabno={0} style={{width: '99%!important'}}>{body.HTIME}</div>
                              </td>
                          </tr>
                          <tr>
                              <td align="center" style={{ fontSize: '0.55em' }}><font size={-1}>(дата)</font></td>
                              <td align="center" style={{ fontSize: '0.55em' }}><font size={-1}>(час)</font></td>
                          </tr>
                          <tr>
                              <td> Реєстраційний №</td>
                              <td>
                                  <div className="fc " p="HNUMREG" tabno={0} style={{width: '99%!important'}}>{body.HNUMREG}</div>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                  </td>
              </tr>
              <tr>
                  <td>
                      <table width="100%" border={1} cellSpacing={0} cellPadding={0} className="tmplPageDoc content tmplKvt">
                          <tbody className="table-template" p="template:TAB1">
                          <tr>
                              <td width="20%" style={{ fontSize: '1em', verticalAlign: 'top' }}> Виявлені помилки:</td>
                          </tr>
                          {body.T1RXXXXG1S?.map((item, idx) => {
                              return(
                              <tr align="center" className="table-template-row" p="template:TAB1" key={idx}>
                                <td style={{ fontSize: '1em' }} className="td_unln_light dm-tblcell dm-cell-val not-editable-cell">
                                    <div className="fc " p="TAB1(?).T1RXXXXG1S" tabno={1}
                          style={{textAlign: 'left', width: '99%!important'}}>{item}
                                    </div>
                                </td>
                              </tr>
                              )}
                          )}
                          </tbody>
                      </table>
                  </td>
              </tr>
              <tr>
                  <td>
                      <table width="100%" border={1} cellSpacing={0} cellPadding={0} className="tmplPageDoc content tmplKvt">
                          <tbody>
                          <tr>
                              <td rowSpan={2} width="20%" style={{ fontSize: '1em', verticalAlign: 'top' }}> Відправник:</td>
                              <td width="85%" style={{ fontSize: '1em', fontWeight: 'bold' }}>
                                  <div style={{width: '100%'}} className="fc " p="HSENDER" tabno={0}>{body.HSENDER}</div>
                              </td>
                          </tr>
                          <tr>
                              <td align="center" style={{ fontSize: '0.55em' }}><font size={-1}>(інформація про відправника)</font></td>
                          </tr>
                          </tbody>
                      </table>
                  </td>
              </tr>
              <tr>
                  <td>
                      <table width="100%" border={1} cellSpacing={0} cellPadding={0} className="tmplPageDoc content tmplKvt">
                          <tbody>
                          <tr>
                              <td style={{ fontSize: '1em', fontWeight: 'bold' }}>
                                  <div style={{width: '100%'}} className="fc " p="TEXT" tabno={0}>{body.TEXT}</div>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                  </td>
              </tr>
              </tbody>
          </table>
      </div>
  )
}

export default F1499102
