import { Form, Spinner } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'
import api from '../../api'
// import { useEffect, useState } from 'react'

interface KeyfileModalProps {
  show: boolean
  handleClose: (needReloadDataAfterClose: boolean) => void
}

const KeyfileModal = ({ show, handleClose }: KeyfileModalProps) => {
  const [file, setFile] = useState<File>()
  const [password, setPassword] = useState('')
  const [showSpinner, setShowSpinner] = useState<boolean>(false)

  const importAccount = () => {
    setShowSpinner(true)
    const reqData = new FormData()
    reqData.append('data', JSON.stringify({ password }))
    reqData.append('file', file as unknown as Blob)
    api.post('/accounts/import-from-keyfile', reqData)
      .then(res => {
        handleClose(true)
      })
      .catch(e => {
        // TODO: buttify error
        console.log(e)
      })
      .finally(() => {
        setShowSpinner(false)
      })
  }

  return (
            <Modal show={show} onHide={() => { handleClose(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Додати ФОП з ЕЦП</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Файл підпису</Form.Label>
                            <Form.Control type="file" onChange={(e: any) => { setFile(e.target.files[0]) }}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control type="password" onChange={(e: any) => { setPassword(e.target.value) }}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="reject" onClick={(e: any) => { handleClose(false) }}>Close</Button>
                    <Button variant="success" onClick={importAccount}>
                        {showSpinner && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} Save</Button>
                </Modal.Footer>
            </Modal>
  )
}

export default KeyfileModal
