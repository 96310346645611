import Modal from 'react-bootstrap/Modal'
import F1300204 from './F1300204'
import { type IDocument } from '../../interfaces/document'
import './documentView.scss'
import F0103308 from './F0103308'
import F1499102 from './F1499102'
import F1391103 from './F1391103'
import FTEXT from './FTEXT'
import F1391104 from './F1391104'

interface DocumentViewModalProps {
  doc: IDocument
  show: boolean
  handleClose: () => void
}

const DocumentViewModal = ({ show, doc, handleClose }: DocumentViewModalProps) => {
  if (doc !== null && (doc?.taxDoc?.Head === undefined || doc.taxDoc.Head === null)) {
    doc.type = 'FTEXT'
  }
  return (
        <Modal show={show} dialogClassName="modal-90w" onHide={() => { handleClose() }}>
            <Modal.Header closeButton>
                <h6>{doc?.type}</h6>
            </Modal.Header>
            <Modal.Body>
                {
                    {
                      F1300204: <F1300204 doc={doc}/>,
                      F0103308: <F0103308 doc={doc}/>,
                      F1499102: <F1499102 doc={doc}/>,
                      J1499102: <F1499102 doc={doc}/>,
                      F1391103: <F1391103 doc={doc}/>,
                      F1391104: <F1391104 doc={doc}/>,
                      FTEXT: <FTEXT doc={doc}/>
                    }[doc?.type]
                }
            </Modal.Body>
        </Modal>
  )
}

export default DocumentViewModal
