import Login from './components/login/login'
import PrivateRoute from './components/privateRoute'
import AccountPage from './components/account/account'
import { Navigate } from 'react-router-dom'
import React from 'react'
import type { RouteObject } from 'react-router/dist/lib/context'
import Dashboard from './components/dashboard/dashboard'
import AppBar from './components/navbar/appBar'
import AccountEdit from './components/account/accountEdit'
import IncomeStatement from './components/incomeStatement/incomeStatement'
import DigitalSignature from './components/digitalSignature/digitalSignature'

export const router: RouteObject[] = [
  {
    path: '/login',
    element: <Login/>
  },
  {
    element: <AppBar></AppBar>,
    children: [
      {
        path: '/',
        element: <PrivateRoute></PrivateRoute>,
        children: [
          {
            index: true,
            element: <Navigate to="/account" />
          },
          {
            path: '/account',
            element: <AccountPage/>
          },
          {
            path: '/account/:id',
            element: <AccountEdit/>
          },
          {
            path: '/digitalSignature',
            element: <DigitalSignature/>
          },
          {
            path: '/incomeStatement',
            element: <IncomeStatement/>
          },
          {
            path: '/dashboard',
            element: <Dashboard/>
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/"/>
  }
]
