// @ts-nocheck
import './taxTypes'
import TaxDocumentProps from '../../interfaces/taxDocumentProps'

interface F1300204Data extends ITaxDocBody {
  HFILL: string
  HNAME: string
  HTIN: string
}

// https://magic.reactjs.net/htmltojsx.htm

const F1300204 = ({ doc }: TaxDocumentProps) => {
  return (
      <table id="gbox_615a0b7f-cfb8-4ea8-af8b-4bce8e7438e5" cellSpacing={0} cellPadding={0} border={0} align="center"
             className="ui-jdm">
          <tbody>
          <tr>
              <td style={{ height: '20px' }} colSpan={3}/>
          </tr>
          <tr>
              <td style={{ width: '20px' }}/>
              <td align="center" style={{ verticalAlign: 'top' }}>
                  <div id="gboxcont_615a0b7f-cfb8-4ea8-af8b-4bce8e7438e5">
                      <table form="true" cellSpacing={0} cellPadding={0} border={0} className="tmplPageDoc content"
                             id="615a0b7f-cfb8-4ea8-af8b-4bce8e7438e5" tabIndex={0} width="100%">
                          <tbody>
                          <tr>
                              <td>
                                  <table width="100%" border={0} cellPadding={0} cellSpacing={4}>
                                      <tbody>
                                      <tr>
                                          <td colSpan={4} align="center"
                                              style={{ fontSize: '1.25em', fontWeight: 'bold' }}>Запит <br
                                              clear="none"/> про
                                              отримання витягу щодо стану розрахунків з бюджетами <br clear="none"/> та
                                              цільовими фондами за даними органів ДПС
                                          </td>
                                      </tr>
                                      <tr className="dm-tblrow dm-row-val">
                                          <td width="35%"/>
                                          <td width="15%" align="right"
                                              style={{ fontSize: '1.25em', fontWeight: 'bold' }}> від
                                          </td>
                                          <td width="15%" className="td_unln dm-tblcell dm-cell-val not-editable-cell">
                                              <div style={{ width: '100%', textAlign: 'center' }}
                                                   className="edtCss dm-field-noupdate" tabno={0} cmrow={0} p="HFILL"
                                                   ma="true" id="HFILL">{(doc.taxDoc.Body as F1300204Data).HFILL.getTaxDate()}
                                              </div>
                                          </td>
                                          <td/>
                                      </tr>
                                      <tr>
                                          <td colSpan={4} align="center"
                                              style={{ fontSize: '1.25em', fontWeight: 'bold' }}>(проставляється поточна
                                              дата
                                              відправлення Запиту до органів ДПС)
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </td>
                          </tr>
                          <tr>
                              <td><br/></td>
                          </tr>
                          <tr>
                              <td>
                                  <table border={0} cellSpacing={2} cellPadding={0} width="100%">
                                      <tbody>
                                      <tr className="dm-tblrow dm-row-val">
                                          <td width="20%"><b>Платник податків</b></td>
                                          <td className="td_unln dm-tblcell dm-cell-val not-editable-cell">
                                              <div style={{ width: '100%', textAlign: 'left' }}
                                                   className="edtCss dm-field-noupdate" tabno={0} cmrow={1} p="HNAME"
                                                   ma="true" id="HNAME">{(doc.taxDoc.Body as F1300204Data).HNAME}
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td/>
                                          <td align="center">(найменування ; прізвище, ім'я, по батькові)</td>
                                      </tr>
                                      <tr className="dm-tblrow dm-row-val">
                                          <td><b>Податковий номер </b></td>
                                          <td className="td_unln dm-tblcell dm-cell-val not-editable-cell">
                                              <div style={{ width: '100%', textAlign: 'left' }}
                                                   className="edtCss dm-field-noupdate" tabno={0} cmrow={2} p="HTIN"
                                                   ro="true" ma="true" id="HTIN">{(doc.taxDoc.Body as F1300204Data).HTIN}
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td/>
                                          <td align="center">(податковий номер платника податку* або серія (за
                                              наявності) та номер паспорта**)
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </td>
                          </tr>
                          <tr>
                              <td><br/></td>
                          </tr>
                          <tr>
                              <td>
                                  <table border={0} cellSpacing={2} cellPadding={0} width="100%">
                                      <tbody>
                                      <tr>
                                          <td>*Зазначається код за ЄДРПОУ платника податку або реєстраційний
                                              (обліковий) номер платника податків, який присвоюється контролюючими
                                              органами, або реєстраційний номер облікової картки платника податків –
                                              фізичної особи.
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>**Серію (за наявності) та номер паспорта зазначають фізичні особи, які
                                              мають відмітку у паспорті про право здійснювати будь-які платежі за
                                              серією та номером паспорта.
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                  </div>
              </td>
              <td style={{ width: '20px' }}/>
          </tr>
          <tr>
              <td style={{ height: '20px' }} colSpan={3}/>
          </tr>
          </tbody>
      </table>
  )
}

export default F1300204
