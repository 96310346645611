import { Form, Spinner, Table } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useEffect, useState } from 'react'
import api from '../../api'
import { type IDashboardRow } from '../../interfaces/dashboard'
import DashboardTableRow from './dashboardTableRow'
import './dashboardTable.scss'

interface DashboardTableProps {
  searchParams: URLSearchParams
  handleSelectionChanged: (data: IDashboardRow[] | null) => void
}

const DashboardTable = ({ searchParams, handleSelectionChanged }: DashboardTableProps) => {
  const [data, setData] = useState<IDashboardRow[] | null>(null)
  const [reloadData, setReloadData] = useState<Date>(new Date())
  const [offset, setOffset] = useState<number | null>(null)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [selectAll, setSelectAll] = useState<boolean>(false)

  useEffect(() => {
    data?.forEach(item => {
      item.isChecked = selectAll
    })
  }, [selectAll])

  useEffect(() => {
    getData()
    // setSelectAll(false)
  }, [reloadData])

  useEffect(() => {
    setOffset(0)
    setHasMore(true)
    setData(null)
    setReloadData(new Date())
  }, [searchParams])

  useEffect(() => {
    handleSelectionChanged(data?.filter(item => { return item.isChecked }) ?? null)
  }, [data])

  const getData = () => {
    if (offset === null) {
      return
    }
    const queryParams = searchParams.toString()
    api.get(`dashboard?${queryParams}`, {
      params: {
        offset
      }
    })
      .then(res => {
        let newData: IDashboardRow[]
        newData = (res.data as IDashboardRow[])
        if (newData.length < 30) {
          setHasMore(false)
        }
        if (data !== null) {
          newData = data?.concat(res.data as IDashboardRow[])
        }
        setOffset(newData.length)
        setData(newData)
      }
      )
      .catch(e => { console.log(e) }
      )
  }

  const dataLen = (data: any): number => {
    if (data != null) {
      return data.length
    }
    return 0
  }

  const handleRowSelectionChanged = (row: IDashboardRow | null) => {
    data?.forEach(item => {
      if (item.id === row?.id) {
        item.isChecked = row?.isChecked
      }
    })
    handleSelectionChanged(data?.filter(item => { return item.isChecked }) ?? null)
  }

  return (
      <InfiniteScroll dataLength={dataLen(data)} next={getData} hasMore={hasMore} loader={
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner animation="border" variant="success" />
              </div>} style={{ overflow: 'unset' }}>
          <Table striped>
              <thead>
              <tr>
                  <th><Form.Check onChange={(e: any) => { setSelectAll(!selectAll) }} checked={selectAll}/></th>
                  <th>ІПН</th>
                  <th>ПІБ</th>
                  <th className="text-center">I квартал</th>
                  <th className="text-center">Півріччя</th>
                  <th className="text-center">9 місяців</th>
                  <th className="text-center">Рік</th>
              </tr>
              </thead>
              <tbody>
              {data?.map(item => {
                return (
                    <DashboardTableRow key={item.id} row={item} checked={selectAll} handleRowSelectionChanged={handleRowSelectionChanged}></DashboardTableRow>
                )
              })}
              </tbody>
          </Table>
      </InfiniteScroll>
  )
}
export default DashboardTable
