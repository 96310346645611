import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import './login.scss'
import logo from '../../logo.svg'
import { FaLock, FaRegEnvelope } from 'react-icons/fa'
import { useAuth } from '../../hooks/authProvider'

const Login = () => {
  const [data, setData] = useState({ email: '', password: '' })
  const auth = useAuth()

  const handleLogin = async (e: any) => {
    e.preventDefault()
    await auth.login(data.email, data.password)
  }

  return (
        <div className="d-flex flex-row align-items-center justify-content-center"
             style={{ width: '100wv', minHeight: '100vh' }}>
            <div className="d-flex flex-column flex-fill logo-form-container">
                <div className="logo d-flex flex-row align-items-center justify-content-center">
                    <img src={logo} alt="logo"/>
                </div>
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <Form className="form-container" onSubmit={handleLogin}>
                    <Form.Group className="mb-3 input-container" controlId="formBasicEmail">
                        <Form.Control
                            onChange={(e: any) => { setData({ ...data, email: e.target.value }) }}
                            value={data.email}
                            required
                            type="email"
                            placeholder="email"
                            className=""
                        />
                        <FaRegEnvelope className="icon"/>
                    </Form.Group>
                    <Form.Group className="mb-3 input-container" controlId="formBasicPassword">
                        <Form.Control
                            onChange={(e: any) => { setData({ ...data, password: e.target.value }) }}
                            value={data.password}
                            required
                            type="password"
                            placeholder="password"
                            color="warning"
                        />
                        <FaLock className="icon"/>
                    </Form.Group>
                        <Button className="login-btn custom-primary-button" variant="primary" type="submit">Login</Button>
                </Form>
            </div>
        </div>
  )
}

export default Login
