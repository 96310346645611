import {
  Button,
  ButtonGroup,
  Container,
  Form,
  InputGroup,
  Row, Spinner,
  Stack,
  ToggleButton
} from 'react-bootstrap'
import AccountTable from './accountTable'
import { FaSearch, FaTimes } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import './account.scss'
import KeyfileModal from '../keyfileUpload/keyfileUpload'
import { useSearchParams } from 'react-router-dom'
import { convertStringToBoolean } from '../../utils/convert'
import GoogleImportAccountModal from './googleImportAccountModal'
import api from '../../api'

const Account = () => {
  // search params
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchString, setSearchString] = useState<string>(searchParams.get('search') ?? '')
  const [isActive, setIsActive] = useState<boolean | null>(convertStringToBoolean(searchParams.get('isActive') ?? 'true'))
  const [isDeregistred, setIsDeregistred] = useState<boolean | null>(convertStringToBoolean(searchParams.get('isDeregistred')))
  const [isKeyProblem, setIsKeyProblem] = useState<boolean | null>(convertStringToBoolean(searchParams.get('isKeyProblem')))

  const [showKeyfileModal, setShowKeyfileModal] = useState<boolean>(false)
  const [showGoogleImportAccountModal, setShowGoogleImportAccountModal] = useState<boolean>(false)
  const [search, setSearch] = useState<string>(searchParams.get('search') ?? '')
  const [total, setTotal] = useState<number>(0)
  const [actionSpinner, setActionSpinner] = useState<string>('')

  useEffect(() => {
    if (searchString !== '') {
      searchParams.set('search', searchString)
      setSearchParams(searchParams)
    } else if (searchParams.has('search')) {
      searchParams.delete('search')
      setSearchParams(searchParams)
    }
  }, [searchString])

  useEffect(() => {
    if (isActive !== null) {
      searchParams.set('isActive', String(isActive))
      setSearchParams(searchParams)
    } else if (searchParams.has('isActive')) {
      searchParams.delete('isActive')
      setSearchParams(searchParams)
    }
  }, [isActive])

  useEffect(() => {
    if (isDeregistred !== null && isDeregistred) {
      searchParams.set('isDeregistred', String(isDeregistred))
      setSearchParams(searchParams)
    } else if (searchParams.has('isDeregistred')) {
      searchParams.delete('isDeregistred')
      setSearchParams(searchParams)
    }
  }, [isDeregistred])

  useEffect(() => {
    if (isKeyProblem !== null && isKeyProblem) {
      searchParams.set('isKeyProblem', String(isKeyProblem))
      setSearchParams(searchParams)
    } else if (searchParams.has('isKeyProblem')) {
      searchParams.delete('isKeyProblem')
      setSearchParams(searchParams)
    }
  }, [isKeyProblem])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setSearchString(search)
    }, 300)
    return () => { clearTimeout(timeOutId) }
  }, [search])

  const reloadData = () => {
    searchParams.set('reloadTime', String((new Date()).getTime()))
    setSearchParams(searchParams)
  }

  const handleCloseKeyfileModal = (needReloadData: boolean) => {
    if (needReloadData) {
      reloadData()
    }
    setShowKeyfileModal(false)
  }

  const handleCloseGoogleImportAccountModal = (needReloadData: boolean) => {
    if (needReloadData) {
      reloadData()
    }
    setShowGoogleImportAccountModal(false)
  }

  const handleTotal = (value: number) => {
    setTotal(value)
  }

  const handleUpdateData = () => {
    setActionSpinner('updateData')
    api.post('accounts/updateAll')
      .then(res => {
        reloadData()
      })
      .catch(e => {})
      .finally(() => { setActionSpinner('') })
  }

  const isActiveFilters = [
    { name: 'Всі', value: 'null' },
    { name: 'Активні', value: 'true' },
    { name: 'Не активні', value: 'false' }
  ]

  return (
      <Container className="pageContainer account">
        <Row>
            <Stack direction="horizontal">
                <div className="mx-4 pt-3 lh-1">
                    <p className="fs-3 fw-bold">{total}</p>
                </div>
                <div className="p-2">
                    <InputGroup className="search-container">
                        <Form.Control
                            className="search-input"
                            placeholder="Пошук..."
                            value={search}
                            onChange={(e: any) => {
                              setSearch(e.target.value)
                            }}
                        />
                        {search !== ''
                          ? <FaTimes className="icon" onClick={(e: any) => {
                            setSearch('')
                          }}/>
                          : <FaSearch className="icon"/>}
                    </InputGroup>
                </div>
                <div className="p-2 ms-auto">
                    <Button className="add-button" onClick={(e) => { setShowKeyfileModal(true) }}>Додати</Button>
                    <KeyfileModal show={showKeyfileModal} handleClose={handleCloseKeyfileModal}/>
                    <Button className="add-button mx-3" onClick={(e) => { setShowGoogleImportAccountModal(true) }}>Google sheet</Button>
                    <GoogleImportAccountModal show={showGoogleImportAccountModal} handleClose={handleCloseGoogleImportAccountModal}/>
                    <Button className="add-button" onClick={(e) => { handleUpdateData() }}>
                        {actionSpinner === 'updateData' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} Оновити данні з ДПС
                    </Button>
                </div>
            </Stack>
        </Row>
          <Row>
              <Stack direction="horizontal">
                  <ButtonGroup size="sm">
                      {
                          isActiveFilters.map((item, idx) => (
                              <ToggleButton
                                  key={idx}
                                  style={{ minWidth: '9em' }}
                                  id={`filter1-${idx}`}
                                  type="radio"
                                  variant={'outline-primary'}
                                  name={'isActive'}
                                  value={item.value}
                                  checked={String(isActive) === item.value}
                                  onChange={(e) => {
                                    setIsActive(convertStringToBoolean(e.currentTarget.value))
                                  }}
                              >{item.name}</ToggleButton>
                          ))}
                  </ButtonGroup>

                  <ButtonGroup size="sm" className="ps-4">
                      <ToggleButton
                          id="toggle-check"
                          type="checkbox"
                          variant={'outline-danger'}
                          checked={isDeregistred ?? false}
                          value="1"
                          onChange={(e) => {
                            setIsDeregistred(e.currentTarget.checked)
                          }}
                      >
                          Зняті з реєстрації
                      </ToggleButton>
                  </ButtonGroup>

                  <ButtonGroup size="sm" className="ps-4">
                      <ToggleButton
                          id="isKeyProblem"
                          type="checkbox"
                          variant={'outline-warning'}
                          checked={isKeyProblem ?? false}
                          value="1"
                          onChange={(e) => {
                            setIsKeyProblem(e.currentTarget.checked)
                          }}
                      >
                          ЕЦП
                      </ToggleButton>
                  </ButtonGroup>
              </Stack>
          </Row>
          <Row className="account-table">
          <AccountTable searchParams={searchParams} handleTotal={handleTotal}/>
        </Row>
      </Container>
  )
}

export default Account
