import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  InputGroup,
  Row, Spinner,
  Stack,
  ToggleButton
} from 'react-bootstrap'
import { FaSearch, FaTimes } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import DigitalSignatureTable from './digitalSignatureTable'
import api from '../../api'
import { useSearchParams } from 'react-router-dom'
import type IAccount from '../../interfaces/account'

const DigitalSignature = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchString, setSearchString] = useState<string>(searchParams.get('ipnCode') ?? '')
  const [filter, setFilter] = useState<string>(searchParams.get('filter') ?? 'withoutDocument')

  const [selectedRows, setSelectedRows] = useState<IAccount[] | null>(null)
  const [search, setSearch] = useState<string>(searchParams.get('search') ?? '')
  const [total, setTotal] = useState<number>(0)
  const [actionSpinner, setActionSpinner] = useState<string>('')
  const filters = [
    { name: 'Без документа', value: 'withoutDocument' },
    { name: 'Документ (не відправлений)', value: 'withValidDocument' },
    { name: 'Відправлено в ДПС', value: 'withSentDocument' },
    { name: 'Прийнято', value: 'withAcceptedDocument' },
    { name: 'Відхилено', value: 'withRejectedDocument' }
  ]

  useEffect(() => {
    if (searchString !== '') {
      searchParams.set('search', searchString)
      setSearchParams(searchParams)
    } else if (searchParams.has('search')) {
      searchParams.delete('search')
      setSearchParams(searchParams)
    }
  }, [searchString])

  useEffect(() => {
    if (filter !== '') {
      searchParams.set('filter', filter)
      setSearchParams(searchParams)
    } else if (searchParams.has('filter')) {
      searchParams.delete('filter')
      setSearchParams(searchParams)
    }
  }, [filter])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setSearchString(search)
    }, 300)
    return () => { clearTimeout(timeOutId) }
  }, [search])
  const handleSelectionChanged = (data: IAccount[] | null) => {
    setSelectedRows(data)
  }

  const handleTotal = (value: number) => {
    setTotal(value)
  }
  const handleAction = (operation: string, selectedRows: IAccount[]) => {
    setActionSpinner(operation)
    let data = {}
    if (selectedRows.length !== 0) {
      data = { ids: selectedRows.map((item) => { return item.id }) }
    }

    searchParams.forEach((value, key) => {
      switch (key) {
        case 'reloadTime':
          break
        case 'reportPeriodId':
        case 'reportYear':
          Object.assign(data, { [key]: Number(value) })
          break
        default:
          Object.assign(data, { [key]: value })
      }
    })

    api.post(`/digitalSignature/${operation}`, data)
      .then(res => {
        searchParams.set('reloadTime', String((new Date()).getTime()))
        setSearchParams(searchParams)
      })
      .catch(() => {})
      .finally(() => { setActionSpinner('') })
  }

  return (

        <Container className="pageContainer">
            <Row>
                <Stack direction="horizontal" gap={4}>
                    <div className="mx-4 pt-3 lh-1">
                        <p className="fs-3 fw-bold">{total}</p>
                    </div>
                    <div className="p-2">
                        <InputGroup className="search-container">
                            <Form.Control
                                className="search-input"
                                placeholder="Пошук..."
                                value={search}
                                onChange={(e: any) => {
                                  setSearch(e.target.value)
                                }}
                            />
                            {search !== ''
                              ? <FaTimes className="icon" onClick={(e: any) => {
                                setSearch('')
                              }}/>
                              : <FaSearch className="icon"/>}
                        </InputGroup>
                    </div>
                    {
                        {
                          withoutDocument: <div className="p-2 ms-auto">
                                                <Button className="add-button" onClick={(e: any) => { handleAction('createAll', []) }}>
                                                    {actionSpinner === 'createAll' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} Додати всім</Button>
                                                <Button className="add-button mx-3" onClick={(e: any) => { handleAction('createSelected', selectedRows!) }} disabled={selectedRows?.length === 0}>
                                                    {actionSpinner === 'createSelected' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} Додати вибраним({selectedRows?.length})
                                                </Button>
                                            </div>,
                          withValidDocument: <div className="p-2 ms-auto">
                                                <Button className="add-button" onClick={(e: any) => { handleAction('sendAll', []) }}>
                                                    {actionSpinner === 'sendAll' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}  Надіслати всі </Button>
                                                <Button className="add-button mx-3" onClick={(e: any) => { handleAction('sendSelected', selectedRows!) }} disabled={selectedRows?.length === 0}>
                                                    {actionSpinner === 'sendSelected' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}  Надіслати вибрані({selectedRows?.length})
                                                </Button>
                                            </div>,
                          withRejectedDocument: <div className="p-2 ms-auto">
                                <Button className="add-button" onClick={(e: any) => { handleAction('createAll', []) }}>
                                    {actionSpinner === 'createAll' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}  Додати всім</Button>
                                <Button className="add-button mx-3" onClick={(e: any) => { handleAction('createSelected', selectedRows!) }} disabled={selectedRows?.length === 0}>
                                    {actionSpinner === 'createSelected' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}  Додати вибраним({selectedRows?.length})
                                </Button>
                            </div>
                        }[filter]
                    }

                </Stack>
            </Row>
            <Row>
                <Col className="px-4 py-2">
                    <ButtonGroup size="sm">
                        {filters.map((item, idx) => (
                            <ToggleButton
                                key={idx}
                                style={{ minWidth: '9em' }}
                                id={`filter-${idx}`}
                                type="radio"
                                variant={'outline-primary'}
                                name="filter"
                                value={item.value}
                                checked={filter === item.value}
                                onChange={(e) => { setFilter(e.currentTarget.value) }}
                            >{item.name}</ToggleButton>
                        ))}
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className="account-table">
                <DigitalSignatureTable searchParams={searchParams} handleSelectionChanged={handleSelectionChanged} handleTotal={handleTotal}/>
            </Row>
        </Container>

  )
}

export default DigitalSignature
