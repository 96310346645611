import axios, { type AxiosError } from 'axios'
import { toast } from 'react-toastify'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
})

api.interceptors.response.use((response) => response, (error: AxiosError) => {
  const expectedError = error.response != null && error.response.status >= 400

  if (expectedError && error?.response?.status === 401 && window.location.pathname !== '/login') {
    const searchParams = new URLSearchParams()
    searchParams.set('goto', window.location.pathname + window.location.search)
    window.location.href = `/login?${searchParams.toString()}`
  }
  if (expectedError) {
    const errorBody = (error.response?.data as any).message !== undefined ? (error.response?.data as any).message : error.response?.data

    // TODO: add requestId header to toast

    toast.error(`error: ${errorBody}`, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined
    })
  }
  throw error
})
export default api
