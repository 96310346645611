import { Button, Col, Dropdown, Form, NavItem, NavLink, Row } from 'react-bootstrap'

import { type CSSProperties, useEffect, useState } from 'react'
import { type IIncomeStatement } from '../../interfaces/incomeStatement'
import api from '../../api'
import DocumentViewModal from '../document/documentView'
import { type IDocument } from '../../interfaces/document'
import { FaEye, FaFileAlt, FaRegFilePdf } from 'react-icons/fa'
import { convertISODateToDatetimeString } from '../../utils/convert'
import { BsFiletypeXml } from 'react-icons/bs'
import { RiKey2Line } from 'react-icons/ri'
import { type IAccountKeyFile } from '../../interfaces/account'

export interface IncomeStatementTableRowProps {
  row: IIncomeStatement
  checked: boolean
  handleRowSelectionChanged: (row: IIncomeStatement | null) => void
}

const IncomeStatementTableRow = ({ row, checked, handleRowSelectionChanged }: IncomeStatementTableRowProps) => {
  const [selected, setSelected] = useState<boolean>(false)
  const [showDocumentViewModal, setShowDocumentViewModal] = useState<boolean>(false)
  const [document, setDocument] = useState<IDocument | null>(null)

  useEffect(() => {
    setSelected(checked)
    setDocument(null)
  }, [checked])

  useEffect(() => {
    row.isChecked = selected
    handleRowSelectionChanged(row)
  }, [selected])

  const handleViewDocument = (documentId: number) => {
    api.get(`/document/${documentId}`)
      .then(res => {
        setDocument(res.data as IDocument)
        setShowDocumentViewModal(true)
      })
      .catch(e => {
        // TODO: buttify error
        console.log(e)
      })
  }

  const handleViewReceipt = (receiptId: number) => {
    api.get(`/receipt/${receiptId}`)
      .then(res => {
        const doc = res.data as IDocument
        doc.type = 'J1499102'
        setDocument(doc)
        setShowDocumentViewModal(true)
      })
      .catch(e => {
        // TODO: buttify error
        console.log(e)
      })
  }

  const handleCreateDocument = (id: number) => {
    api.post(`/incomeStatement/${id}/newDocument`)
      .then(res => {
        const document = res.data as IDocument
        if (row.documents === undefined) {
          row.documents = [document]
        } else {
          row.documents?.unshift(document)
        }
        setDocument(document)
        setShowDocumentViewModal(true)
      })
      .catch(e => {
        // TODO: buttify error
        console.log(e)
      })
  }

  const handleSend = (documentId: number) => {
    api.post(`/document/${documentId}/send`)
      .then(res => {
        console.log(res)
      })
      .catch(e => {
        // TODO: buttify error
        console.log(e)
      })
  }

  const handleCloseDocumentViewModal = () => {
    setShowDocumentViewModal(false)
  }

  const getKeyClass = (): CSSProperties => {
    const signKey: IAccountKeyFile | undefined = row.account.keyFiles?.find((item: IAccountKeyFile) => item.isSign)

    if (signKey === undefined) {
      return { color: '#fb3f4c' }
    } else if (signKey?.isMain === true) {
      return { color: '#4ebb7b' }
    }
    return { color: '#c4750b' }
  }

  return (
      <tr key={row.id}>
          <td className="align-middle"><Form.Check.Input checked={selected} onChange={() => {
            setSelected(!selected)
          }}/></td>
          <td>
              <div className="d-flex">
                  <div className="me-1"><RiKey2Line size={12} style={getKeyClass()}/></div>
                  <div>{row.account.ipnCode}</div>
              </div>
          </td>
          <td>
              <a href={window.location.origin + `/account/${row.account.id}`} target="_blank" rel="noreferrer">{row.account.name}</a>
          </td>
          <td className="text-end">{row.prevPeriodIncome?.incomeAmount.toLocaleString('uk-UA', { minimumFractionDigits: 2 })}</td>
          <td className="text-end">{row.incomeAmount.toLocaleString('uk-UA', { minimumFractionDigits: 2 })}</td>
          <td className="text-end">{row.taxAmount.toLocaleString('uk-UA', { minimumFractionDigits: 2 })}</td>
          <td className="text-end"></td>
          <td className="text-end">
              <Row>
                  <Col md={1}>
                      <a href={process.env.REACT_APP_API_URL + `/document/${row.documents?.[0].id}/pdf`}
                         hidden={row.documents?.[0].registrationNumber == null}>
                          <FaRegFilePdf size={16} className="text-primary"/>
                      </a>
                  </Col>
                  <Col md={2}>
                      {row.documents?.[0].id !== undefined &&
                          <FaEye className="mx-1" style={{ color: 'grey' }} onClick={() => {
                            handleViewDocument(row.documents?.[0].id ?? 0)
                          }} size={18}/>
                      }
                  </Col>
                  <Col md={3} className="d-flex flex-row">
                      {row.documents?.[0]?.receipts?.[0]?.id !== undefined &&
                          <FaFileAlt size={16} onClick={() => {
                            handleViewReceipt(row.documents?.[0].receipts[0].id ?? 0)
                          }}
                                     className={((row.documents?.[0]?.receipts?.[0]?.status >= 0) ? 'receipt-success' : 'receipt-error')}/>
                      }

                      {row.documents?.[0]?.receipts?.[1]?.id !== undefined &&
                          <FaFileAlt size={16} onClick={() => {
                            handleViewReceipt(row.documents?.[0].receipts[1]?.id ?? 0)
                          }}
                                     className={((row.documents?.[0]?.receipts?.[1]?.status >= 0) ? 'receipt-success' : 'receipt-error')}/>
                      }
                  </Col>
                  <Col md={3}>
                      <Dropdown as={NavItem} style={{ marginRight: 10 }}>
                          <Dropdown.Toggle as={NavLink}> docs1
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ left: -200, width: 350 }}>
                              <Dropdown.Item onClick={() => {
                                handleCreateDocument(row.id)
                              }}> +Додати</Dropdown.Item>
                              <Dropdown.Divider/>
                              {row.documents?.map(document => {
                                return (<Dropdown.Item key={document.id} style={{ display: 'flex' }} as={'div'}>
                                    <div className="my-1 mx-2" onClick={() => {
                                      handleViewDocument(document.id)
                                    }}>
                                        {document.type} - {convertISODateToDatetimeString(document.createdAt)}
                                    </div>

                                    <div className="pl-2 pe-4 pt-1">
                                            <a href={process.env.REACT_APP_API_URL + `/document/${document.id}/xml`}>
                                                <BsFiletypeXml size={20}
                                                                className="text-success d-flex flex-row-reverse"/>
                                            </a>
                                    </div>

                                    {document.status === 'valid' &&
                                        <Button className="add-button" size="sm" onClick={() => {
                                          handleSend(document.id)
                                        }}>Send</Button>}

                                    <div className="pt-2" style={{ display: 'flex' }}>
                                        {document.receipts?.map(receipt => {
                                          return (<FaFileAlt size={16} key={receipt.id} onClick={() => {
                                            handleViewReceipt(receipt.id ?? 0)
                                          }}
                                                               className={((receipt?.status >= 0) ? 'receipt-success' : 'receipt-error')}/>)
                                        })}
                                    </div>
                                </Dropdown.Item>)
                              })}
                          </Dropdown.Menu>
                      </Dropdown>
                  </Col>
              </Row>
              <DocumentViewModal show={showDocumentViewModal} doc={document!}
                                 handleClose={handleCloseDocumentViewModal}/>
          </td>
      </tr>
  )
}

export default IncomeStatementTableRow
