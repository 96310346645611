import { Form, Spinner, Table } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useEffect, useState } from 'react'
import api from '../../api'
import DigitalSignatureTableRow from './digitalSignatureTableRow'
import './digitalSignatureTable.scss'
import type IAccount from '../../interfaces/account'

interface DigitalSignatureTableProps {
  searchParams: URLSearchParams
  handleSelectionChanged: (data: IAccount[] | null) => void
  handleTotal: (total: number) => void
}

const DigitalSignatureTable = ({ searchParams, handleSelectionChanged, handleTotal }: DigitalSignatureTableProps) => {
  const [data, setData] = useState<IAccount[] | null>(null)
  const [reloadData, setReloadData] = useState<Date>(new Date())
  const [offset, setOffset] = useState<number | null>(null)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [selectAll, setSelectAll] = useState<boolean>(false)

  useEffect(() => {
    data?.forEach(item => {
      item.isChecked = selectAll
    })
  }, [selectAll])

  useEffect(() => {
    getData()
  }, [reloadData])

  useEffect(() => {
    setOffset(0)
    setHasMore(true)
    setData(null)
    setReloadData(new Date())
  }, [searchParams])

  useEffect(() => {
    handleSelectionChanged(data?.filter(item => { return item.isChecked }) ?? null)
  }, [data])

  const getData = () => {
    if (offset === null) {
      return
    }

    const queryParams = searchParams.toString()
    api.get(`digitalSignature?${queryParams}`, {
      params: {
        offset
      }
    })
      .then(res => {
        let newData: IAccount[]
        newData = (res.data as IAccount[])
        if (newData.length < 30) {
          setHasMore(false)
        }
        if (data !== null) {
          newData = data?.concat(res.data as IAccount[])
        }
        setOffset(newData.length)
        setData(newData)
      }
      )
      .catch(e => { console.log(e) }
      )
    api.get(`digitalSignature/total?${queryParams}`)
      .then(res => {
        handleTotal(res.data.count)
      }
      )
      .catch(e => { console.log(e) }
      )
  }

  const dataLen = (data: any): number => {
    if (data != null) {
      return data.length
    }
    return 0
  }

  const handleRowSelectionChanged = (row: IAccount | null) => {
    data?.forEach(item => {
      if (item.id === row?.id) {
        item.isChecked = row?.isChecked
      }
    })
    handleSelectionChanged(data?.filter(item => { return item.isChecked }) ?? null)
  }

  // const getDownloadUrl = (): string => {
  //   const queryParams = searchParams.toString()
  //   return process.env.REACT_APP_API_URL + `/digitalSignature/download?${queryParams}`
  // }

  return (
        <InfiniteScroll dataLength={dataLen(data)} next={getData} hasMore={hasMore} loader={
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Spinner animation="border" variant="success" />
                </div>} style={{ overflow: 'unset' }}>
            <Table striped className="income-report-table">
                <thead>
                <tr>
                    <th className="select-column"><Form.Check onChange={(e: any) => {
                      setSelectAll(!selectAll)
                    }} checked={selectAll}/></th>
                    <th className="ipn-column">ІПН</th>
                    <th className="name-column">ПІБ</th>
                    <th className="text-end">Активний ключ</th>
                    <th className="text-end">Новий ключ</th>
                    <th className="text-center actions-column">
                        {/* <a href={getDownloadUrl()} className="d-flex flex-row-reverse"> */}
                        {/*    <FaRegFileExcel size={20} className="text-success d-flex flex-row-reverse"/> */}
                        {/* </a> */}
                    </th>
                </tr>
                </thead>
                <tbody>
                {data?.map(item => {
                  return (
                        <DigitalSignatureTableRow key={item.id} row={item} checked={selectAll}
                                                  handleRowSelectionChanged={handleRowSelectionChanged}></DigitalSignatureTableRow>
                  )
                })}
                </tbody>
            </Table>
        </InfiniteScroll>
  )
}
export default DigitalSignatureTable
