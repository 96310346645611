type Dictionary<T> = Record<string, T>

export const DOCUMENT_STATUS: Dictionary<string> = {
  accepted: 'Прийнято',
  rejected: 'Відхилено',
  sent: 'Надіслано',
  cabinet: 'cabinet',
  valid: 'Не відправлено',
  manually: 'Вручну'
}
