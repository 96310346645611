import { Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'
import api from '../../api'
// import { useEffect, useState } from 'react'

interface GoogleImportIncomeStatementModalProps {
  show: boolean
  handleClose: (needReloadDataAfterClose: boolean) => void
  reportYear: number
}

const GoogleImportIncomeStatementModal = ({ show, handleClose, reportYear }: GoogleImportIncomeStatementModalProps) => {
  const [fileUrl, setFileUrl] = useState<File>()
  const [needUpdate, setNeedUpdate] = useState(true)

  const importAccount = () => {
    api.post('/incomeStatement/import-from-googlesheet', { fileUrl, needUpdate, reportYear })
      .then(res => {
        handleClose(true)
      })
      .catch(e => {
        // TODO: buttify error
        console.log(e)
      })
      .finally(() => {

      })
  }

  return (
        <Modal show={show} onHide={() => { handleClose(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Імпорт данних про доходи з Google sheets за {reportYear} рік</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Посилання на файл</Form.Label>
                        <Form.Control type="input" onChange={(e: any) => { setFileUrl(e.target.value) }}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Оновити дані про доходи для всіх ФОП</Form.Label>
                        <Form.Check checked={needUpdate} onChange={(e: any) => { setNeedUpdate(e.target.checked) }}/>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="reject" onClick={(e: any) => { handleClose(false) }}>Close</Button>
                <Button variant="success" onClick={importAccount}>Save</Button>
            </Modal.Footer>
        </Modal>
  )
}

export default GoogleImportIncomeStatementModal
