import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../hooks/authProvider'

const PrivateRoute = () => {
  const auth = useAuth()
  if (auth.user == null) { return <Navigate to="/login" /> }
  return <Outlet />
}

export default PrivateRoute
