// @ts-nocheck
import './taxTypes'
import TaxDocumentProps from '../../interfaces/taxDocumentProps'

interface F1391103Data extends ITaxDocBody {
    HBOS: string
    HEXECUTOR: string
    HFILL: string
    HNAME: string
    HNUM: number
    HPOST: string
    HSTI: string
    HTEL: string
    HTIN: string
    T1RXXXXG1S: string[]
    T1RXXXXG2S: string[]
    T1RXXXXG3S: string[]
    T1RXXXXG4S: string[]
}

// https://magic.reactjs.net/htmltojsx.htm

const F1391103 = ({ doc }: TaxDocumentProps) => {
    const body = doc.taxDoc.Body as F1391103Data
  return (
      <table id="gbox_45f74f46-c1ff-4ff7-8746-d7010f88cc35" cellSpacing={0} cellPadding={0} border={0} align="center"
             className="ui-jdm">
          <tbody>
          <tr>
              <td style={{height: '20px'}} colSpan={3}/>
          </tr>
          <tr>
              <td style={{width: '20px'}}/>
              <td align="center" style={{verticalAlign: 'top'}}>
                  <div id="gboxcont_45f74f46-c1ff-4ff7-8746-d7010f88cc35">
                      <table form="true" cellSpacing={0} cellPadding={0} border={0} className="tmplPageDoc content"
                             id="45f74f46-c1ff-4ff7-8746-d7010f88cc35" tabIndex={0} width="100%">
                          <tbody>
                          <tr>
                              <td>
                                  <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                                      <tbody>
                                      <tr>
                                          <td width="30%"/>
                                          <td/>
                                          <td width="30%">Додаток 2 <br/> до Порядку обміну електронними документами з
                                              контролюючими органами <br/> (пункт 11 розділу III)
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  <table width="100%" border={0} cellSpacing={4} cellPadding={0}>
                                      <tbody>
                                      <tr className="dm-tblrow dm-row-val">
                                          <td align="center" width="5%"
                                              className="dm-tblcell dm-cell-val not-editable-cell">
                                              <div className="dm-field-noupdate" tabno={0}
                                                   style={{textAlign: 'center', width: '160px!important'}} cmrow={0}
                                                   p="HFILL" ma="true" id="HFILL">{body.HFILL.getTaxDate()}
                                              </div>
                                          </td>
                                          <td align="center" width="3%"> №</td>
                                          <td align="center" width="10%"
                                              className="dm-tblcell dm-cell-val not-editable-cell">
                                              <div className="dm-field-noupdate" tabno={0}
                                                   style={{width: '99%!important'}} cmrow={1} p="HNUM" ma="true"
                                                   id="HNUM">{body.HNUM}
                                              </div>
                                          </td>
                                          <td align="center" width="40%"/>
                                      </tr>
                                      <tr>
                                          <td align="center">(дата)</td>
                                          <td align="center"/>
                                          <td align="center"> (номер)</td>
                                          <td align="center"/>
                                      </tr>
                                      <tr className="dm-tblrow dm-row-val">
                                          <td align="center" colSpan={3} rowSpan={9}/>
                                          <td align="center" className="dm-tblcell dm-cell-val not-editable-cell">
                                              <div style={{width: '100%', textAlign: 'left'}}
                                                   className="dm-field-noupdate" tabno={0} cmrow={2} p="HSTI" ma="true"
                                                   id="HSTI">{body.HSTI}
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td align="center"> (найменування контролюючого органу)</td>
                                      </tr>
                                      <tr className="dm-tblrow dm-row-val">
                                          <td align="center" className="dm-tblcell dm-cell-val not-editable-cell">
                                              <div style={{width: '100%', textAlign: 'left'}}
                                                   className="dm-field-noupdate" tabno={0} cmrow={3} p="HNAME" ma="true"
                                                   id="HNAME">{body.HNAME}
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td align="center"><font size={-1}>(найменування (або прізвище, ім'я, по
                                              батькові (за наявності) для фізичних осіб) автора )</font></td>
                                      </tr>
                                      <tr className="dm-tblrow dm-row-val">
                                          <td align="center" className="dm-tblcell dm-cell-val not-editable-cell">
                                              <div className="dm-field-noupdate" tabno={0}
                                                   style={{textAlign: 'left', width: '160px!important'}} cmrow={4}
                                                   p="HTIN" ro="true" ma="true" id="HTIN">{body.HTIN}
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td align="center"><font size={-1}>(код за ЄДРПОУ / реєстраційний номер
                                              облікової картки платника податків або серія (за наявності), номер
                                              паспорта <sup>1</sup>)</font></td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </td>
                          </tr>
                          <tr>
                              <td><br/></td>
                          </tr>
                          <tr>
                              <td>
                                  <table width="100%" border={0} cellSpacing={0} cellPadding={4}>
                                      <tbody>
                                      <tr>
                                          <td align="center"><b>Повідомлення <br/> про надання інформації щодо
                                              кваліфікованого електронного підпису</b></td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </td>
                          </tr>
                          <tr>
                              <td><br/></td>
                          </tr>
                          <tr>
                              <td>
                                  <table width="100%" border={0} cellSpacing={0} cellPadding={4}>
                                      <tbody>
                                      <tr>
                                          <td> Надаю інформацію про сертифікати відповідальних осіб, яким делеговано
                                              право підпису електронних документів:
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  <table width="100%" border={1} cellSpacing={0} cellPadding={0} bordercolor="#000000"
                                         id="IN" style={{borderWidth: 1}}>
                                      <thead>
                                      <tr>
                                          <td style={{borderWidth: 1}} width="25%" align="center">Прізвище, ім'я, по батькові (за наявності)
                                              відповідальної особи
                                          </td>
                                          <td style={{borderWidth: 1}} width="10%" align="center">Реєстраційний номер облікової картки платника
                                              податків або серія (за наявності), номер паспорта <sup>1</sup></td>
                                          <td style={{borderWidth: 1}} width="25%" align="center">Посада</td>
                                          <td style={{borderWidth: 1}} width="20%" align="center">Тип підпису<sup>2</sup></td>
                                      </tr>
                                      </thead>
                                      <tbody className="table-template" p="template:TAB1">
                                      <tr align="center" className="dm-row-val dm-tblrow dm-tbl" style={{}}
                                          id="9f300980-e7cc-4185-7a4c-a1f60c62d7eb" rn={0} tabn={1}>
                                          <td style={{borderWidth: 1}} className="dm-cell-val not-editable-cell">
                                              <div style={{width: '100%', textAlign: 'left'}}
                                                   className="dm-field-noupdate" tabno={1} cmrow={5} p="T1RXXXXG1S"
                                                   tabn={1} id="9f300980-e7cc-4185-7a4c-a1f60c62d7eb_T1RXXXXG1S">{body.T1RXXXXG1S[0]}
                                              </div>
                                          </td>
                                          <td style={{borderWidth: 1}} className="dm-cell-val not-editable-cell">
                                              <div className="dm-field-noupdate" tabno={1}
                                                   style={{textAlign: 'left', width: '99%!important'}} cmrow={6}
                                                   p="T1RXXXXG2S" tabn={1}
                                                   id="9f300980-e7cc-4185-7a4c-a1f60c62d7eb_T1RXXXXG2S">{body.T1RXXXXG2S[0]}
                                              </div>
                                          </td>
                                          <td style={{borderWidth: 1}} className="dm-cell-val not-editable-cell">
                                              <div className="dm-field-noupdate" tabno={1}
                                                   style={{textAlign: 'left', width: '99%!important'}} cmrow={7}
                                                   p="T1RXXXXG3S" tabn={1}
                                                   id="9f300980-e7cc-4185-7a4c-a1f60c62d7eb_T1RXXXXG3S">{body.T1RXXXXG3S[0]}
                                              </div>
                                          </td>
                                          <td style={{borderWidth: 1}} className="dm-cell-val not-editable-cell">
                                              <div className="dm-field-noupdate" tabno={1}
                                                   style={{textAlign: 'left', width: '99%!important'}} cmrow={8}
                                                   p="T1RXXXXG4S" tabn={1}
                                                   id="9f300980-e7cc-4185-7a4c-a1f60c62d7eb_T1RXXXXG4S">{body.T1RXXXXG4S[0]}
                                              </div>
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </td>
                          </tr>
                          <tr>
                              <td><br/></td>
                          </tr>
                          <tr>
                              <td>
                                  <table width="100%" border={0} cellSpacing={4} cellPadding={0}>
                                      <tbody>
                                      <tr className="dm-tblrow dm-row-val">
                                          <td rowSpan={2} width="20%" valign="top">Керівник</td>
                                          <td width="10%" className="td_unln"/>
                                          <td width="2%"/>
                                          <td width="35%" className="td_unln dm-tblcell dm-cell-val not-editable-cell"
                                              align="center">
                                              <div style={{width: '100%', textAlign: 'left'}}
                                                   className="dm-field-noupdate" tabno={0} cmrow={9} p="HBOS" ma="true"
                                                   id="HBOS">{body.HBOS}
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td align="center">(підпис)</td>
                                          <td align="center"/>
                                          <td align="center">(ініціали та прізвище)</td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </td>
                          </tr>
                          <tr>
                              <td><br/></td>
                          </tr>
                          <tr>
                              <td>
                                  <table width="100%" border={0} cellSpacing={4} cellPadding={0}>
                                      <tbody>
                                      <tr className="dm-tblrow dm-row-val">
                                          <td width="20%" valign="top">Виконавець:</td>
                                          <td width="2%"/>
                                          <td width="35%" className="td_unln dm-tblcell dm-cell-val not-editable-cell"
                                              align="center">
                                              <div style={{width: '100%', textAlign: 'left'}}
                                                   className="dm-field-noupdate" tabno={0} cmrow={10} p="HPOST"
                                                   ma="true" id="HPOST">{body.HPOST}
                                              </div>
                                          </td>
                                          <td width="2%"/>
                                          <td width="35%" className="td_unln dm-tblcell dm-cell-val not-editable-cell"
                                              align="center">
                                              <div style={{width: '100%', textAlign: 'left'}}
                                                   className="dm-field-noupdate" tabno={0} cmrow={11} p="HEXECUTOR"
                                                   ma="true" id="HEXECUTOR">{body.HEXECUTOR}
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td colSpan={2}/>
                                          <td align="center">(посада)</td>
                                          <td align="center"/>
                                          <td align="center">(ініціали та прізвище)</td>
                                      </tr>
                                      <tr className="dm-tblrow dm-row-val">
                                          <td rowSpan={2} valign="top" nowrap={1}>Контактний телефон:</td>
                                          <td/>
                                          <td className="td_unln dm-tblcell dm-cell-val not-editable-cell"
                                              align="center">
                                              <div style={{width: '100%', textAlign: 'left'}}
                                                   className="dm-field-noupdate" tabno={0} cmrow={12} p="HTEL" ma="true"
                                                   id="HTEL">{body.HTEL}
                                              </div>
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  <table width="100%" border={0} cellSpacing={4} cellPadding={0}>
                                      <tbody>
                                      <tr>
                                          <td><sup>1</sup> Для фізичних осіб, які через свої релігійні переконання
                                              відмовляються від прийняття реєстраційного номера облікової картки
                                              платника податків та офіційно повідомили про це відповідний контролюючий
                                              орган і мають відмітку у паспорті.
                                          </td>
                                      </tr>
                                      <tr>
                                          <td><sup>2</sup> У графі зазначається: Директор / Бухгалтер / Підпис
                                              податкових накладних / Печатка / Технологічний сертифікат/.../Уповноважена
                                              особа.
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                  </div>
              </td>
              <td style={{width: '20px'}}/>
          </tr>
          <tr>
              <td style={{height: '20px'}} colSpan={3}/>
          </tr>
          </tbody>
      </table>

  )
}

export default F1391103
